// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-user-js": () => import("./../../../src/pages/user.js" /* webpackChunkName: "component---src-pages-user-js" */),
  "component---src-templates-category-blog-js": () => import("./../../../src/templates/category/blog.js" /* webpackChunkName: "component---src-templates-category-blog-js" */),
  "component---src-templates-category-product-js": () => import("./../../../src/templates/category/product.js" /* webpackChunkName: "component---src-templates-category-product-js" */),
  "component---src-templates-commerce-product-js": () => import("./../../../src/templates/commerce/product.js" /* webpackChunkName: "component---src-templates-commerce-product-js" */),
  "component---src-templates-node-blog-js": () => import("./../../../src/templates/node/blog.js" /* webpackChunkName: "component---src-templates-node-blog-js" */),
  "component---src-templates-node-harmonogram-js": () => import("./../../../src/templates/node/harmonogram.js" /* webpackChunkName: "component---src-templates-node-harmonogram-js" */),
  "component---src-templates-node-page-js": () => import("./../../../src/templates/node/page.js" /* webpackChunkName: "component---src-templates-node-page-js" */),
  "component---src-templates-node-person-js": () => import("./../../../src/templates/node/person.js" /* webpackChunkName: "component---src-templates-node-person-js" */),
  "component---src-templates-pagination-blog-js": () => import("./../../../src/templates/pagination/blog.js" /* webpackChunkName: "component---src-templates-pagination-blog-js" */),
  "component---src-templates-pagination-product-js": () => import("./../../../src/templates/pagination/product.js" /* webpackChunkName: "component---src-templates-pagination-product-js" */)
}

