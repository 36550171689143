/**
 * Implement Gatsby's Browser APIs in this file.
 * @see: https://www.gatsbyjs.org/docs/browser-apis/
 */

// Import all js dependencies.
import 'jquery/dist/jquery.min.js'
import 'popper.js/dist/popper.min'
import 'bootstrap/js/dist/util'
import 'bootstrap/js/dist/dropdown'
import 'bootstrap/js/dist/collapse'
import 'bootstrap/js/dist/button'
// import 'bootstrap/js/dist/scrollspy'

import 'owl.carousel/dist/owl.carousel'
// import 'smooth-scroll/dist/smooth-scroll.polyfills'
import 'magnific-popup/dist/jquery.magnific-popup'

import s4n from './static/s4n'

// @see: https://github.com/jlengstorf/gatsby-with-apollo/blob/master/gatsby-browser.js
import React from 'react';
import { ApolloProvider } from 'react-apollo';
import { client } from './src/apollo/client';

// SHOP EXT START
// import { Route, Switch } from "react-router-dom";
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router'
import { cartFetch, setCartToken } from './src/components/s4n/Shop/ext/actions'

// import configureStore, { history } from './src/components/s4n/Shop/ext/utils/configureStore';
import { createStore, applyMiddleware, compose, combineReducers } from 'redux'
import thunk from 'redux-thunk';
import { createBrowserHistory } from 'history'
import { routerMiddleware } from 'connected-react-router'

import fontSize from './src/s4n/state/reducers/fontSize';
import highContrast from './src/s4n/state/reducers/highContrast';
import combineShopReducers from './src/components/s4n/Shop/ext/reducers'

import { connectRouter } from 'connected-react-router'

const history = createBrowserHistory();

const rootReducer = history => combineReducers({
    router: typeof history === 'undefined' ? null : connectRouter(history),
    fontSize,
    highContrast,
    shopReducers: combineShopReducers(history), // Important call combineReducers!!!
});

/**
 * @see: https://github.com/zalmoxisus/redux-devtools-extension
 * Thanks to composeEnhancers we can debug redux store state in dev tools!
 */
// const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const __REDUX_DEVTOOLS_EXTENSION_COMPOSE__ = typeof window !== `undefined` ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : false;
const composeEnhancers = __REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

/**
 * @param preloadedState
 * @returns {Store<any, Action> & *}
 */
export default function configureStore(preloadedState) {
  return createStore(
    rootReducer(history),
    preloadedState,
    composeEnhancers(
      applyMiddleware(
        routerMiddleware(history),
        thunk
      )
    )
  );
}

const store = configureStore();
store.dispatch(setCartToken(localStorage.getItem('cartToken') || Math.random().toString(36).substr(2)))
store.dispatch(cartFetch());
// SHOP EXT STOP



export const wrapRootElement = ({ element }) => (
  <Provider store={store}>
    <ApolloProvider client={client}>
      <ConnectedRouter history={history}>
        {element}
      </ConnectedRouter>
    </ApolloProvider>
  </Provider>
);


const $ = require("jquery")
const g_soft4NetFrontConfig = s4n($, window, document);



// export function onClientEntry() {
//   // console.log('onClientEntry')
// }

// export const onInitialClientRender = () => {
// // console.log(`onInitialClientRender`)
// }

/**
 * Called when the user changes routes, including on the initial load of the app
 */
export function onRouteUpdate({ location, prevLocation }) {
// console.log(`onRouteUpdate`)
// console.log('new pathname', location.pathname)
// console.log('old pathname', prevLocation ? prevLocation.pathname : null)

  // const g_soft4NetFrontConfig = window.g_soft4NetFrontConfig;
  // const $ = window.jQuery; // we want to have jQuery from our import not from => import $ from "jquery"

  // @see: https://stackoverflow.com/questions/60357083/does-not-use-passive-listeners-to-improve-scrolling-performance-lighthouse-repo start
  $.event.special.touchstart = {
      setup: function( _, ns, handle ) {
          this.addEventListener("touchstart", handle, { passive: !ns.includes("noPreventDefault") });
      }
  };
  $.event.special.touchmove = {
      setup: function( _, ns, handle ) {
          this.addEventListener("touchmove", handle, { passive: !ns.includes("noPreventDefault") });
      }
  };
  $.event.special.wheel = {
      setup: function( _, ns, handle ){
          this.addEventListener("wheel", handle, { passive: true });
      }
  };
  $.event.special.mousewheel = {
      setup: function( _, ns, handle ){
          this.addEventListener("mousewheel", handle, { passive: true });
      }
  };
  // @see: https://stackoverflow.com/questions/60357083/does-not-use-passive-listeners-to-improve-scrolling-performance-lighthouse-repo stop

  // Run a function when the page is fully loaded including graphics
  // $(window).load(function () {
  $(window).on('load', () => {
// console.info('window loaded')
    
    $('.owl-carousel').trigger('refresh.owl.carousel')
    // $('.owl-carousel:not(.hero)').trigger('refresh.owl.carousel')

    g_soft4NetFrontConfig
      .owlCarousel('mainSlider', '.owl-carousel.hero')
      .owlCarousel('centeredSlider', '.owl-carousel.centered')
      .owlCarousel('standardSlider', '.owl-carousel.standard')
      .owlCarousel('testimonialsSlider', '.owl-carousel.testimonials')
    ;
  });

  g_soft4NetFrontConfig
    .rot13()
    .enableMagnificPopup(process.env.SOFT4NET_SOURCE_BASE_URL)
    .transformToBootstrapAccoridion()
    // .enableServiceTawkTo()
    // .updateBootstrapMenu()
    // .scrollSpy()
    // .waypoints()
    // .owlCarousel()
    // .servicePopupScroll()
    // .fancybox()

    .otherActions()
    // .setScreenSize()
    .owlCarousel('mainSlider', '.owl-carousel.hero')
    .owlCarousel('centeredSlider', '.owl-carousel.centered')
    .owlCarousel('standardSlider', '.owl-carousel.standard')
    .owlCarousel('testimonialsSlider', '.owl-carousel.testimonials')

    .extraFunctions()
  ;
}


// Missing implementation:
// - magnific popup
