import { TOGGLE_HIGH_CONTRAST_MODE } from './../actions/types';

const initialState = {
    isHighContrastMode: false,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case TOGGLE_HIGH_CONTRAST_MODE:
            return {
                ...state, 
                isHighContrastMode: action.isHighContrastMode,
            };
        default:
            return state;
    }
};