// @see: https://github.com/jlengstorf/gatsby-with-apollo/blob/master/src/apollo/client.js
import { ApolloClient, ApolloLink, HttpLink, InMemoryCache, FetchPolicy } from 'apollo-boost';
import fetch from 'isomorphic-fetch';
import { setContext } from 'apollo-link-context'

// export const client = new ApolloClient({
//   uri: process.env.SOFT4NET_SOURCE_BASE_URL_GRAPHQL,
//   fetch,
// });



// @see: https://github.com/apollographql/apollo-client/issues/4420
const authLink = setContext((_, { headers }) => {
    return {
       headers: {
          ...headers,
          //Authorization: 'Basic ' + btoa('<login>:<pass>'),
          Authorization: 'Basic ' + btoa(`${process.env.BASIC_AUTH_USERNAME}:${process.env.BASIC_AUTH_PASSWORD}`),
       }
    }
});
 
const httpLink = new HttpLink({
    uri: process.env.SOFT4NET_SOURCE_BASE_URL_GRAPHQL,
    fetch,
    // fetchOptions: {
    //    mode: 'no-cors'
    // }
});
 
export const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
});
